var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("router-link", { staticClass: "logo", attrs: { to: "/" } }, [
        _vm.logo
          ? _c("img", { staticClass: "sidebar-logo", attrs: { src: _vm.logo } })
          : _vm._e()
      ]),
      _c("div", { staticClass: "navbar-bottom" }, [
        _c("div", { staticClass: "flex", staticStyle: { "flex-grow": "1" } }),
        _c(
          "div",
          { staticClass: "flex", staticStyle: { "flex-shrink": "0" } },
          [
            _c(
              "div",
              { staticClass: "pointer" },
              [
                _c(
                  "el-badge",
                  {
                    attrs: { value: _vm.notice, max: 99, hidden: !_vm.notice }
                  },
                  [
                    _c("i", {
                      staticClass: "el-icon-bell",
                      staticStyle: { color: "#000", "font-size": "20px" }
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "el-tooltip",
              {
                staticStyle: { color: "#000" },
                attrs: { content: "返回", effect: "dark", placement: "bottom" }
              },
              [
                _c("i", {
                  staticClass:
                    "el-icon-arrow-left font-size4 right-menu-item hover-effect",
                  on: { click: _vm.vBack }
                })
              ]
            ),
            _c(
              "el-dropdown",
              {
                staticClass: "avatar-container right-menu-item hover-effect",
                staticStyle: { color: "#000" },
                attrs: { trigger: "click" }
              },
              [
                _c("div", { staticClass: "avatar-wrapper" }, [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        (_vm.user.deptName || "") +
                          " " +
                          (_vm.user.employeeName || "")
                      )
                    )
                  ]),
                  _c("i", { staticClass: "el-icon-caret-bottom" })
                ]),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "user-dropdown",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown"
                  },
                  [
                    _c("el-dropdown-item", [
                      _c("span", { on: { click: _vm.showUserInfo } }, [
                        _vm._v("个人信息")
                      ])
                    ]),
                    _c("el-dropdown-item", [
                      _c("span", { on: { click: _vm.changePwd } }, [
                        _vm._v("修改密码")
                      ])
                    ]),
                    _c("el-dropdown-item", [
                      _c(
                        "span",
                        {
                          staticStyle: { display: "block" },
                          on: { click: _vm.logout }
                        },
                        [_vm._v("退出系统")]
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("ChangePwd", { ref: "ChangePwd" }),
      _c("UserInfo", { ref: "UserInfo" }),
      _c("noticeDialog", {
        key: _vm.keyIndex,
        ref: "noticeDialog",
        on: { formSubmit: _vm.translateText }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }