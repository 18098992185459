//
//
//
//
//
//

import screenfull from 'screenfull';

export default {
  name: 'Screenfull',
  data: function data() {
    return {
      isFullscreen: false };

  },
  mounted: function mounted() {
    this.init();
  },
  beforeDestroy: function beforeDestroy() {
    this.destroy();
  },
  methods: {
    click: function click() {
      if (!screenfull.isEnabled) {
        this.handleWarning(
        '您的浏览器不支持全屏，请升级浏览器版本或者换用主流浏览器！');


        return false;
      }
      var el = document.getElementById('fullscreen');
      screenfull.toggle(el);
    },
    change: function change() {
      this.isFullscreen = screenfull.isFullscreen;
    },
    init: function init() {
      if (screenfull.enabled) {
        screenfull.on('change', this.change);
      }
    },
    destroy: function destroy() {
      if (screenfull.enabled) {
        screenfull.off('change', this.change);
      }
    } } };