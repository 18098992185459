import "core-js/modules/es.date.to-string";import "core-js/modules/es.object.to-string";import "core-js/modules/es.regexp.exec";import "core-js/modules/es.regexp.to-string";import "core-js/modules/es.string.replace";import _objectSpread from "/Users/learning/Documents/workspace/kindstar/customer-orders/node_modules/@babel/runtime/helpers/esm/objectSpread2"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapState } from 'vuex';
// import Breadcrumb from './Breadcrumb/index.vue'
// import Hamburger from './Hamburger/index.vue'
import Screenfull from './Screenfull/index.vue';
import noticeDialog from './notice/ListDialog.vue';
import ChangePwd from './ChangePwd/index.vue';
import UserInfo from './UserInfo/index.vue';
// import Search from "./HeaderSearch";
import Lock from './Lock';
import { lockPage, needTagsView, title } from '@/settings';
export default {
  inject: ['reload'],
  components: {
    // Breadcrumb,
    // Hamburger,
    Screenfull: Screenfull,
    ChangePwd: ChangePwd,
    UserInfo: UserInfo,
    noticeDialog: noticeDialog,
    // Search,
    Lock: Lock },

  data: function data() {
    return {
      lockPage: lockPage,
      title: title,
      logo: require('@/assets/imgs/logo.png'),
      keyIndex: '' };

  },
  computed: _objectSpread(_objectSpread({},
  mapGetters({
    sidebar: 'sidebar',
    user: 'user/user' })),

  mapState({
    user: function user(state) {return state.user.user.userVo || {};},
    notice: function notice(state) {return state.user.notice;} })),


  methods: {
    translateText: function translateText(text) {
      this.keyIndex = text;
    },
    toNotice: function toNotice() {
      this.$refs.noticeDialog.getData();
      this.$refs.noticeDialog.open({
        title: '消息待办' });

    },
    //利用随机种子生成uuid
    uuid: function uuid() {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
      }
      var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        var r = (d + Math.random() * 16) % 16 | 0; // d是随机种子
        d = Math.floor(d / 16);
        return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
      });

      return uuid;
    },
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    changePwd: function changePwd() {
      this.$refs.ChangePwd.open();
    },
    showUserInfo: function showUserInfo() {
      this.$refs.UserInfo.open();
    },
    refreshTag: function refreshTag() {var _this = this;
      if (needTagsView) {var _this$$route =
        this.$route,path = _this$$route.path,fullPath = _this$$route.fullPath,matched = _this$$route.matched;

        this.$store.
        dispatch('tagsView/refreshView', { path: path, matched: matched }).
        then(function () {
          _this.$nextTick(function () {
            _this.$router.replace({
              path: '/redirect' + fullPath });

          });
        });
      } else {
        this.$emit('refreshMain');
      }
    } } };