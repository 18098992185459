var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "lock pointer",
      on: {
        click: function($event) {
          return _vm.vJump({ name: "lock" })
        }
      }
    },
    [
      _c("i", { staticClass: "iconfont icon-mima" }),
      _c("span", [_vm._v("锁屏")])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }